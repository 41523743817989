<template>
  <v-container fluid class="pa-0 ma-0">
    <v-card>
      <v-btn>실패</v-btn>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'CardFailed',

  data: () => ({
  }),

  mounted () {
    // window.close() // 이건 되네 ㅎ
    // window.opener.cardPayWinPopRef.close() // 이것도 된다
    // 어쩔 수 없이 전역인 window 변수를 만들어서 작업한다.

    // window.alert('카드결제 실패!')
    // * 부모창의 함수를 실행한 후 현재 팝업은 닫는다..
    window.opener.cardPayWindowRefunc('fail')
    // window.opener.cardPayWinPopRef.close()
    //
  },

  methods: {
  }
}
</script>
